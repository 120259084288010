/**
* Global styles for Prose App
*
* Declares global style needs, for the root element and things like font faces.
* Should only contain styles that will remain with css-in-js.
*/

html {
  /* Add smooth scrolling for anchor jumps */
  scroll-behavior: smooth;
  /* reset box sizing method */
  box-sizing: border-box;

  font-family: "Simplon Norm", "Helvetica Neue", Helvetica, sans-serif;

  /* Explicitly declare the base font-size we were using */
  /* https://stackoverflow.com/questions/6905834/should-i-set-the-default-font-size-on-the-body-or-html-element */
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5em;
  letter-spacing: 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Saol, Times, "Times New Roman", serif;
  line-height: 1.3em;
  letter-spacing: 0.5px;
}

/* used to disable scroll when menu is open */
html.prose-no-scroll {
  margin-top: 0 !important;
}

html.prose-no-scroll,
html.prose-no-scroll body {
  overflow: hidden !important;
  height: 100% !important;
}
/* setup border-box as default but enables easy change for other models */
/* https://css-tricks.com/box-sizing/ */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/* hide underlines on links */
a,
a:hover,
a:visited,
a:focus {
  text-decoration: none;
}

[data-whatintent="mouse"] *:focus {
  outline: none;
}

[data-whatintent="keyboard"] .StripeElement--focus {
  outline: rgb(94, 158, 215) auto 5px;
}

@font-face {
  font-family: "Simplon Mono";
  font-weight: 400;
  font-display: fallback;
  src:
    url(../public/fonts/SimplonMono-Regular-WebS.woff2) format("woff2"),
    url(../public/fonts/SimplonMono-Regular-WebS.woff) format("woff");
}

@font-face {
  font-family: "Simplon Mono";
  font-weight: 500;
  font-display: fallback;
  src:
    url(../public/fonts/SimplonMono-Medium-WebS.woff2) format("woff2"),
    url(../public/fonts/SimplonMono-Medium-WebS.woff) format("woff");
}

@font-face {
  font-family: "Simplon Mono";
  font-weight: bold; /* Historic context:
  In the past, "bold" is how the medium font-file as been aliased by both design and dev.
  We don't use the bold font-file as of today. */
  font-display: fallback;
  src:
    url(../public/fonts/SimplonMono-Medium-WebS.woff2) format("woff2"),
    url(../public/fonts/SimplonMono-Medium-WebS.woff) format("woff");
}

@font-face {
  font-family: "Simplon Norm";
  font-weight: 100;
  font-display: fallback;
  src:
    url(../public/fonts/SimplonNorm-Light-WebS.woff2) format("woff2"),
    url(../public/fonts/SimplonNorm-Light-WebS.woff) format("woff");}

@font-face {
  font-family: "Simplon Norm";
  font-weight: normal;
  font-display: fallback;
  src:
    url(../public/fonts/SimplonNorm-Regular-WebS.woff2) format("woff2"),
    url(../public/fonts/SimplonNorm-Regular-WebS.woff) format("woff");}

@font-face {
  font-family: "Simplon Norm";
  font-weight: 500;
  font-display: fallback;
  src:
    url(../public/fonts/SimplonNorm-Medium-WebS.woff2) format("woff2"),
    url(../public/fonts/SimplonNorm-Medium-WebS.woff) format("woff");}

@font-face {
  font-family: "Simplon Norm";
  font-weight: bold;
  font-display: fallback;
  src:
    url(../public/fonts/SimplonNorm-Bold-WebS.woff2) format("woff2"),
    url(../public/fonts/SimplonNorm-Bold-WebS.woff) format("woff");}

@font-face {
  font-family: Saol;
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
  src:
    url(../public/fonts/saol-text-book.woff2) format("woff2"),
    url(../public/fonts/saol-text-book.woff) format("woff");
}

@font-face {
  font-family: Saol;
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src:
    url(../public/fonts/saol-text-medium.woff2) format("woff2"),
    url(../public/fonts/saol-text-medium.woff) format("woff");
}

@font-face {
  font-family: Saol;
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src:
    url(../public/fonts/saol-text-regular.woff2) format("woff2"),
    url(../public/fonts/saol-text-regular.woff) format("woff");
}

@font-face {
  font-family: Saol;
  font-weight: 300;
  font-style: italic;
  font-display: fallback;
  src:
    url(../public/fonts/saol-text-light-italic.eot) format("eot"),
    url(../public/fonts/saol-text-light-italic.woff) format("woff"),
    url(../public/fonts/saol-text-light-italic.ttf) format("ttf");
}
